'use strict';

import { MyndInput, MyndButton } from "./common";
import Integration from '../logic/integration';

class HubSpotView extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.observe({
            owner: '',
            updating: false
        });

        this.onInput = this.onInput.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    async getData() {
        try {
            let data = await Integration.get('hubspot');

            if (data) {
                data = data.data;
                this.owner = data.owner;
            }
        }
        catch (e) {

        }
    }

    async updateData() {
        if(this.updating) {
            return;
        }

        this.updating = true;
        try {
            const dat = {
                owner: this.owner
            };

            await Integration.update('hubspot', dat);
        }
        catch (e) {

        }
        this.updating = false;
    }

    async componentDidMount() {
        this.getData();
    }

    onInput(e) {
        this[e.target.id] = e.target.value;
    }

    onSubmit(e) {
        e.preventDefault();
        if(this.form && this.form.checkValidity() && this.owner.indexOf('@') > -1) {
            this.updateData();
        }
    }

    async render() {
        const spinner = (
            <i class='fas fa-spinner spin'></i>
        );

        return (
            <form ref={f => this.form = f} class='min-width-512 p-4' id='hubspot' novalidate={true}>
                <div class='d-flex flex-wrap'>
                    <div class='form-group min-width-512 text-left material-shadow bg-light rounded'>
                        <h2 class='display-6 bg-dark rounded p-4 text-light'>Mynd@Home</h2>
                        <div class='pl-4 pr-4'>
                            <MyndInput type='email' id='owner' 
                                required={true} 
                                placeholder='contact owner' 
                                onInput={this.onInput}
                                value={this.owner} error='Please enter an email' />
                            <div class='form-group text-right p-2'>
                                <MyndButton onclick={this.onSubmit} style={'width: 125px;'} text={this.updating ? spinner : 'UPDATE'}></MyndButton>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

class ShippoView extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.observe(
            {
                from: null,
                parcel: null,
                updating: false
            }
        );

        this.name = null;
        this.company = null;
        this.phone = null;
        this.email = null;
        this.street1 = null;
        this.street2 = null;
        this.street3 = null;
        this.city = null;
        this.state = null;
        this.zip = null;
        
        this.length = null;
        this.width = null;
        this.height = null;
        this.weight = null;

        this.onInput = this.onInput.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    } 

    async getData() {
        try {
            let data = await Integration.get('shippo');

            if (data) {
                data = data.data;
            }

            if(data && data.from) {
                this.name = data.from.name;
                this.company = data.from.company;
                this.phone = data.from.phone;
                this.email = data.from.email;
                this.street1 = data.from.street1;
                this.street2 = data.from.street2;
                this.street3 = data.from.street3;
                this.city = data.from.city;
                this.state = data.from.state;
                this.zip = data.from.zip;
                this.from = data.from;
            }
            if (data && data.parcel) {
                this.length = data.parcel.length;
                this.width = data.parcel.width;
                this.height = data.parcel.height;
                this.weight = data.parcel.weight;
                this.parcel = data.parcel;
            }
        } catch (e) {
            console.log(e);
        }
    }

    async updateData() {
        if (this.updating) {
            return;
        }

        this.updating = true;
        try {
            const from = {};
            from.name = this.name;
            from.company = this.company;
            from.email = this.email.toLowerCase();
            from.phone = this.phone;
            from.street1 = this.street1;
            from.street2 = this.street2;
            from.street3 = this.street3;
            from.city = this.city;
            from.state = this.state;
            from.zip = this.zip;
            from.country = this.country;

            const parcel = {};
            parcel.length = this.length;
            parcel.width = this.width;
            parcel.height = this.height;
            parcel.weight = this.weight;

            await Integration.update('shippo', {from: from, parcel: parcel});
        }
        catch (e) {

        }
        this.updating = false;
    }

    onInput(e) {
        this[e.target.id] = e.target.value;
    }

    async componentDidMount() {
        this.getData();
    }

    onSubmit(e) {
        e.preventDefault();
        if(this.form && this.form.checkValidity()) {
            this.updateData();
        }
    }

    async render() {
        const spinner = (
            <i class='fas fa-spinner spin'></i>
        );

        return (
            <form ref={f => this.form = f} class='min-width-512 p-4' id='shippo' novalidate={true}>
                <div class='d-flex flex-wrap'>
                    <div class='form-group min-width-512 text-left material-shadow bg-light rounded'>
                        <h2 class="display-6 bg-dark text-white p-4 rounded">Ship From</h2>
                        <div class='pl-4 pr-4 pb-4'>
                        <MyndInput
                            type='name' id='name'
                            required={true} placeholder="name" oninput={this.onInput}
                            value={this.name} error='Please enter a name' /> 
                        <MyndInput
                            type='name' id='company'
                            required={true} placeholder="company" oninput={this.onInput}
                            value={this.company} error='Please enter a company name' /> 
                        <MyndInput
                            type='phone' id='phone'
                            required={true} placeholder="phone" oninput={this.onInput}
                            value={this.phone} error='Please enter a phone' /> 
                        <MyndInput 
                            type='email' id='email'
                            required={true} placeholder="email" oninput={this.onInput}
                            value={this.email} error='Please enter an email' />
                        </div>
                        <div class='pl-4 pr-4 pb-4'>
                        <MyndInput 
                            type='address' id='street1'
                            require={true} placeholder='street1' oninput={this.onInput}
                            value={this.street1} error='Please enter a street address' />
                        <MyndInput
                            type='address' id='street2'
                            placeholder='street2' oninput={this.onInput}
                            value={this.street2} />
                        <MyndInput
                            type='address' id='street3'
                            placeholder='street3' oninput={this.onInput}
                            value={this.street3} />
                        <MyndInput
                            type='text' id='city'
                            require={true} placeholder='city' oninput={this.onInput}
                            value={this.city} error='Please enter a city' />
                        <MyndInput 
                            type='text' id='state'
                            require={true} placeholder='state' oninput={this.onInput}
                            value={this.state} error='Please enter a state' />
                        <MyndInput
                            type='zip' id='zip' pattern='[0-9]{5}'
                            require={true} placeholder='zip' oninput={this.onInput}
                            value={this.zip} error='Please enter a zip' />
                        </div>
                    </div>
                    <div class='form-group min-width-512 text-left bg-light material-shadow rounded'>
                        <h2 class='display-6 p-4 rounded'>Parcel Info</h2>
                        <div class='pl-4 pr-4'>
                        <MyndInput 
                            type='number' style='margin-top: 20px;' id='width' pattern='[0-9]+'
                            require={true} placeholder='width (inches)' oninput={this.onInput}
                            value={this.width} error='Please enter a valid width' />
                        <MyndInput
                            type='number' id='length' pattern='[0-9]+'
                            require={true} placeholder='length (inches)' oninput={this.onInput}
                            value={this.length} error='Please enter a valid length' />
                        <MyndInput
                            type='number' id='height' pattern='[0-9]+'
                            require={true} placeholder='height (inches)' oninput={this.onInput}
                            value={this.height} error='Please enter a valid height' />
                        <MyndInput 
                            type='number' id='weight' pattern='[0-9]+'
                            require={true} placeholder='weight (pounds)' oninput={this.onInput}
                            value={this.weight} error='Please enter a valid weight' />
                        <small>*Width, Height, and Length are in Inches</small>
                        <br />
                        <small>*Weight is in Pounds</small>
                        </div>
                        <div class='form-group text-right p-4 max-width-1024'>
                            <MyndButton onclick={this.onSubmit} style={'width: 125px;'} text={this.updating ? spinner : 'UPDATE'}></MyndButton>
                        </div>
                    </div>
                </div>
            </form>
        )
    }
}

export default class IntegrationsView extends Component {
    constructor(o, parent) {
        super(o, parent);
    }

    async render() {
        let view = null;

        if (!this.edit || this.edit === 'shippo') {
            view = (<ShippoView key='view' />);
        }
        else if(this.edit === 'hubspot') {
            view = (<HubSpotView key='view' />);
        }

        return (
            <div class='flex-fill d-flex allow-scroll-y relative flex-wrap' style='margin-top: 64px' key='view'>
                <ul class='d-flex side-menu max-width-256 flex-column material-shadow' style='background-color: white; padding-left: 0px; margin-bottom: 0;'>
                    <li class={'list-item-nav pointer' + (!this.edit || this.edit === 'shippo' ? ' active' : '')}>
                        <a href='#/integration?edit=shippo'>Shippo</a>
                    </li>
                    <li class={'list-item-nav pointer' + (this.edit === 'hubspot' ? ' active' : '')}>
                        <a href='#/integration?edit=hubspot'>HubSpot</a>
                    </li>
                </ul>
                <div class='flex-fill'>
                    {view}
                </div>
            </div>
        );
    }
}