"use strict";

import Communities from "./community";

const USER_POOL_INFO = {
    UserPoolId: "us-east-2_eC1J5ABrd",
    ClientId: "6qoc8nh7v5uijkc8udt14k8ud7",
};

class LoginHandler {
    constructor() {
        this.userPool = new AmazonCognitoIdentity.CognitoUserPool(USER_POOL_INFO);
        this.cognitoUser = this.userPool.getCurrentUser();
        this.session = null;
        this.sessionCallback = null;
    }

    get groups() {
        if (this.session && this.session.isValid()) {
            const token = this.session.idToken.jwtToken;
            const split = token.split(".");

            if (split.length > 1) {
                const encoded = split[1];
                const decoded = atob(encoded);

                try {
                    const json = JSON.parse(decoded);
                    let groups = [];
                    const cgroups = json["cognito:groups"];

                    if (cgroups) {
                        if (!Array.isArray(cgroups)) {
                            groups.push(cgroups);
                        } else {
                            groups = cgroups;
                        }
                    }

                    return groups;
                } catch (e) {
                    console.log(e);
                }
            }
        }

        return [];
    }

    get email() {
        if (this.session && this.session.isValid()) {
            const token = this.session.idToken.jwtToken;
            const split = token.split(".");

            if (split.length > 1) {
                const encoded = split[1];
                const decoded = atob(encoded);

                try {
                    const json = JSON.parse(decoded);
                    return json.email;
                } catch (e) {
                    console.log(e);
                }
            }
        }

        return "";
    }

    hasGroup(g) {
        const groups = this.groups;
        return groups.indexOf(g) > -1;
    }

    get idToken() {
        return new Promise(async (res, rej) => {
            try {
                const session = await this.sessionData;
                res(session.idToken.jwtToken);
            } catch (e) {
                rej();
            }
        });
    }

    get sessionData() {
        return new Promise((res, rej) => {
            if (this.session) {
                res(this.session);
                return;
            }

            if (this.cognitoUser != null) {
                this.cognitoUser.getSession((err, session) => {
                    if (err) {
                        rej();
                        return;
                    }

                    if (session.isValid()) {
                        this.session = session;

                        if (this.sessionCallback) {
                            this.sessionCallback(this.session);
                        }

                        res(this.session);
                    } else {
                        rej();
                    }
                });
            } else {
                rej();
            }
        });
    }

    globalSignout() {
        if (this.cognitoUser) {
            this.cognitoUser.globalSignOut({
                onSuccess: (result) => {
                    this.cognitoUser = null;
                    this.session = null;
                    Communities.clearCache();
                    window.location.reload();
                },
            });
        }
    }

    signOut() {
        if (this.cognitoUser) {
            this.cognitoUser.signOut();
            this.cognitoUser = null;
            this.session = null;
            Communities.clearCache();
        }
    }

    signIn(user, pass, twofactor) {
        return new Promise((res, rej) => {
            const authData = {
                Username: user,
                Password: pass,
            };

            const authDetails = new AmazonCognitoIdentity.AuthenticationDetails(authData);
            const userData = {
                Username: user,
                Pool: this.userPool,
            };

            this.cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
            const cognitoUser = this.cognitoUser;
            this.cognitoUser.authenticateUser(authDetails, {
                onSuccess: (result) => {
                    this.session = result;
                    res(this.session);
                },
                totpRequired: function (secretCode) {
                    console.log("totprequired");
                    twofactor((answer) => {
                        cognitoUser.sendMFACode(answer, this, "SOFTWARE_TOKEN_MFA");
                    });
                },
                mfaRequired: function () {
                    console.log("mfarequired");
                    twofactor((answer) => {
                        cognitoUser.sendMFACode(answer, this, "SOFTWARE_TOKEN_MFA");
                    });
                },
                newPasswordRequired: (userAttributes, requiredAttributes) => {
                    this.tempAttributes = userAttributes;
                    rej("new password required");
                },
                onFailure: (err) => {
                    console.log(err);
                    rej();
                },
            });
        });
    }

    get isSignedIn() {
        return this.cognitoUser != null && this.session != null && this.session.isValid();
    }

    refreshToken() {
        return new Promise((res, rej) => {
            if (this.cognitoUser == null || this.session == null || this.session.isValid()) {
                res();
                return;
            }

            this.cognitoUser.refreshSession(this.session.getRefreshToken(), (err, sess) => {
                if (err) {
                    res();
                    console.log(err);
                    return;
                }

                this.session = sess;

                if (this.sessionCallback) {
                    this.sessionCallback(this.session);
                }

                res();
            });
        });
    }

    associateSoftwareToken() {
        return new Promise((res, rej) => {
            if (!this.cognitoUser || !this.session || !this.session.isValid()) {
                rej();
                return;
            }

            this.cognitoUser.associateSoftwareToken({
                associateSecretCode: (secret) => {
                    res(secret);
                },
                onFailure: (err) => {
                    rej(err);
                },
            });
        });
    }

    verifySoftwareToken(token) {
        return new Promise((res, rej) => {
            if (!this.cognitoUser || !this.session || !this.session.isValid()) {
                rej();
                return;
            }

            this.cognitoUser.verifySoftwareToken(token, "AuthDevice", {
                onFailure: (err) => {
                    rej(err);
                },
                onSuccess: () => {
                    res();
                },
            });
        });
    }

    enableMFA() {
        return new Promise((res, rej) => {
            if (!this.cognitoUser || !this.session || !this.session.isValid()) {
                rej();
                return;
            }

            const settings = {
                PreferredMfa: true,
                Enabled: true,
            };

            this.cognitoUser.setUserMfaPreference(null, settings, (err, result) => {
                if (err) {
                    rej(err);
                    return;
                }

                console.log(result);
                res(result);
            });
        });
    }

    disableMFA() {
        return new Promise((res, rej) => {
            if (!this.cognitoUser || !this.session || !this.session.isValid()) {
                rej();
                return;
            }

            const settings = {
                PreferredMfa: false,
                Enabled: false,
            };

            this.cognitoUser.setUserMfaPreference(null, settings, (err, result) => {
                if (err) {
                    rej(err);
                    return;
                }

                console.log(result);
                res(result);
            });
        });
    }

    isMFAEnabled() {
        return new Promise((res, rej) => {
            if (!this.cognitoUser || !this.session || !this.session.isValid()) {
                res(false);
                return;
            }

            this.cognitoUser.getUserData((err, data) => {
                if (err) {
                    console.log(err);
                    res(false);
                    return;
                }

                res(data && data.UserMFASettingList && data.UserMFASettingList.indexOf("SOFTWARE_TOKEN_MFA") > -1);
            });
        });
    }

    completeNewPasswordChallenge(pass) {
        return new Promise((res, rej) => {
            if (!this.tempAttributes) {
                rej();
            }

            this.tempAttributes = null;

            this.cognitoUser.completeNewPasswordChallenge(
                pass,
                {},
                {
                    onSuccess: (result) => {
                        this.session = result;
                        res(this.session);
                    },
                    onFailure: (err) => {
                        console.log(err);
                        rej();
                    },
                }
            );
        });
    }
}

export const Login = new LoginHandler();
