'use strict';

import { MyndInput, MyndButton, MyndFloatingButton, MultiCheckboxSelector, ListViewGrid, MyndCheckbox } from "./common";
import Apps from '../logic/apps';
import {Login} from '../logic/login';
import Communities from '../logic/community';
import {URLREGEX, URLREGEXRAW} from '../logic/constants';

class AppEditView extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.onNameInput = this.onNameInput.bind(this);
        this.onIconInput = this.onIconInput.bind(this);
        this.onViveInput = this.onViveInput.bind(this);
        this.onPicoInput = this.onPicoInput.bind(this);
        this.onBuildInput = this.onBuildInput.bind(this);
        this.onDescInput = this.onDescInput.bind(this);
        this.onControllerChecked = this.onControllerChecked.bind(this);

        this.onClick = this.onClick.bind(this);
        this.onDelete = this.onDelete.bind(this);

        this.banner = null;

        if(this.item) { 
            this.observe({name: this.item.name, icon: this.item.iconUrl});
            this.build = this.item.build;
            this.bundle = this.item.bundleId;
            this.description = this.item.description;
        
            this.pico = this.item.picoUrl;
            this.vive = this.item.viveUrl;
            this.controller = this.item.controller;
        }
    }

    async onClick(e) {
        if(e) {
            e.stopPropagation();
            e.preventDefault();
        }

        location.hash = '#/apps';

        try {
            const succ = await this.save();

            if(succ) {
                if(this.parent) {
                    await this.parent.refresh();
                }
            }
        }
        catch (e) { }
    }

    async onDelete(e) {
        if(e) {
            e.stopPropagation();
            e.preventDefault();
        }

        const confirmed = confirm(`Delete ${this.name}?`);

        if(!confirmed) {
            return;
        }

        location.hash = '#/apps';

        try {
            const succ = await this.remove();

            if(succ) {
                if(this.parent) {
                    await this.parent.refresh();
                }
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    async save() {
        try {
            return await Apps.update(this.bundle, this.name, this.build, this.description, this.vive, '', this.pico, '', this.icon, 0, this.controller);
        }
        catch (e) {

        }

        return false;
    }

    async remove() {
        try {
            return await Apps.remove(this.bundle);
        }
        catch (e) {
            console.log(e);
        }

        return false;
    }

    onNameInput(e) {
        if(e.target.value && e.target.value.match(/^[A-Za-z0-9\. ']+$/gi)) {
            this.name = e.target.value;
        }
    }

    onIconInput(e) {
        if(e.target.value && e.target.value.match(URLREGEX)) {
            this.icon = e.target.value;
        }
    }

    onBuildInput(e) {
        const n = parseInt(e.target.value);

        if(!Number.isNaN(n) && n > this.build) {
            this.build = n;
        }
    }

    onDescInput(e) {
        this.description = e.target.value;
    }

    onViveInput(e) {
        if(!e.target.value || e.target.value.match(URLREGEX)) {
            this.vive = e.target.value;
        }
    }

    onPicoInput(e) {
        if(!e.target.value || e.target.value.match(URLREGEX)) {
            this.pico = e.target.value;
        }
    }

    onControllerChecked(e) {
        this.controller = e;
    }

    async render() {
        return (
            <div class='min-width-512 max-width-512 mr-auto ml-auto p-4' style='margin-top: 64px;'>
                <h1 class='display-5 mt-20'>{this.name}</h1>
                <h3>{this.bundle}</h3>
                <img src={this.icon} class='w-100 m-2' />
                <div class='mt-20'>
                    <MyndInput type='number' 
                        placeholder='build'
                        pattern='^[0-9]+$'
                        required={true} 
                        value={this.build}
                        oninput={this.onBuildInput} />
                    <MyndInput type='text' 
                        placeholder='app name' 
                        pattern={`^[A-Za-z0-9\\. \']+$`}
                        required={true}
                        value={this.name}
                        oninput={this.onNameInput}
                        error='Invalid name format' />
                    <MyndInput type='text'
                        placeholder='short description'
                        value={this.description}
                        oninput={this.onDescInput} />
                    <MyndInput type='url'
                        placeholder='icon url'
                        pattern={URLREGEXRAW}
                        required={true}
                        value={this.icon}
                        error='Invalid url format'
                        oninput={this.onIconInput} />
                    <MyndInput type='url'
                        placeholder='vive apk url'
                        pattern={URLREGEXRAW}
                        value={this.vive} 
                        error='Invalid url format'
                        oninput={this.onViveInput} />
                    <MyndInput type='url'
                        placeholder='pico apk url'
                        pattern={URLREGEXRAW}
                        value={this.pico} 
                        error={'Invalid url format'}
                        oninput={this.onPicoInput} />        
                    <MyndCheckbox checked={this.controller}
                        id='app-controller-required'
                        placeholder='Controller required?'
                        onchange={this.onControllerChecked} />
                </div>
                <div class='d-flex w-100 justify-content-between'>
                    <MyndButton type='danger' onclick={this.onDelete}>DELETE</MyndButton>
                    <MyndButton onclick={this.onClick}>DONE</MyndButton>
                </div>
            </div>
        );
    }
}

class AppAddView extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.name = '';
        this.bundle = '';
        this.icon = '';
        this.vive = '';
        this.pico = '';
        this.description = '';

        this.observe({error: 'Invalid bundle format'});
        this.forceError = false;
        this.onNameInput = this.onNameInput.bind(this);
        this.onBundleInput = this.onBundleInput.bind(this);
        this.onIconInput = this.onIconInput.bind(this);
        this.onViveInput = this.onViveInput.bind(this);
        this.onPicoInput = this.onPicoInput.bind(this);
        this.onDescInput = this.onDescInput.bind(this);

        this.onSubmit = this.onSubmit.bind(this);
    }

    async onSubmit(e) {
        e.stopPropagation();
        e.preventDefault();

        if(this.name.match(/^[A-Za-z0-9\. ']+$/gi)
            && this.bundle.match(/^[A-Za-z0-9\\.\_]+$/gi)
            && this.icon.match(URLREGEX)) {

            if(this.vive && !this.vive.match(URLREGEX)) {
                return;
            }    

            if(this.pico && !this.pico.match(URLREGEX)) {
                return;
            }

            try {
                //will need to add tier in when we get to that point
                await Apps.create(this.bundle, this.name, this.description, this.vive, '', this.pico, '', this.icon, 0);

                if(this.onSubmission) {
                    this.onSubmission();
                }
            }
            catch (e) {
                this.forceError = true;
                this.error = e;
                console.log(e);
            }
        }
    }

    onNameInput(e) {
        this.name = e.target.value;
    }

    onBundleInput(e) {
        this.bundle = e.target.value;
    }

    onIconInput(e) {
        this.icon = e.target.value;
    }

    onViveInput(e) {
        this.vive = e.target.value;
    }

    onPicoInput(e) {
        this.pico = e.target.value;
    }

    onDescInput(e) {
        this.description = e.target.value;
    }

    async render() {
        return (
            <div class='bg-white p-2 min-width-512 max-width-512 material-shadow' style='position: fixed; right: 64px; bottom: 64px;'>
                <MyndInput type='text'
                    placeholder='app name'
                    pattern={`^[A-Za-z0-9\\. \']+$`}
                    required={true}
                    value={this.name}
                    oninput={this.onNameInput}
                    error='Invalid name format' />
                <MyndInput type='text'
                    placeholder='bundle id'
                    pattern={`^[A-Za-z0-9\\.\_]+$`}
                    required={true}
                    value={this.bundle}
                    oninput={this.onBundleInput}
                    error={this.error}
                    forceError={this.forceError} />
                <MyndInput type='text'
                    placeholder='short description'
                    value={this.description}
                    oninput={this.onDescInput} />
                <MyndInput type='url'
                    placeholder='icon url'
                    pattern={URLREGEXRAW}
                    required={true}
                    value={this.icon}
                    error='Invalid url format'
                    oninput={this.onIconInput} />
                <MyndInput type='url'
                    placeholder='vive apk url'
                    pattern={URLREGEXRAW}
                    value={this.vive}
                    error='Invalid url format'
                    oninput={this.onViveInput} />
                <MyndInput type='url'
                    placeholder='pico apk url'
                    pattern={URLREGEXRAW} 
                    value={this.pico}
                    error='Invalid url format'
                    oninput={this.onPicoInput} />
                <MyndButton onclick={this.onSubmit}>CREATE</MyndButton>
            </div>
        )
    }
}

class AppListItem extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.observe({
                        isVisible: false,
                        image: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
                        showSaving: false,
                        showCommunitySelector: false
                    });

        this.communitySelection = {};

        this.onCommunityToggle = this.onCommunityToggle.bind(this);
        this.onCommunitiesSelected = this.onCommunitiesSelected.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.div = null;
    }

    async onCommunitiesSelected(results) {
        this.showSaving = true;

        for(let n in this.communitySelection) {
            if(this.communitySelection[n] !== results[n]) {
                //update local values
                this.communitySelection[n] = results[n];

                if(results[n]) {
                    try {
                        await Communities.addApp(n, this.item.bundleId);
                    }
                    catch (e) {

                    }
                }
                else {
                    try {
                        await Communities.removeApp(n, this.item.bundleId);
                    }
                    catch (e) {

                    }
                }
            }
        }

        this.showSaving = false;
        this.onCommunityToggle();
    }

    onCommunityToggle(e) {
        if(e) {
            e.stopPropagation();
            e.preventDefault();
        }

        if(!this.showCommunitySelector) {
            this.showCommunitySelector = true;
        }
        else if(this.showCommunitySelector) {
            this.showCommunitySelector = false;
        }
    }

    onSelect(e) {
        if(e) {
            e.stopPropagation();
            e.preventDefault();
        }

        window.location.hash = '#/apps?edit=' + this.index;
    }

    onScroll(e) {
        if(!this.isVisible) {
            if(this.isInViewport()) {
                this.isVisible = true;

                if(this.view && this.view.base) {
                    this.view.base.removeEventListener('scroll', this.onScroll, false);
                }

                window.removeEventListener('resize', this.onScroll, false);
            }
        }
    }

    isInViewport() {
        if(!this.div) return false;

        var rect = this.div.getBoundingClientRect();
        
        return (
            rect.bottom >= 0 && 
            rect.right >= 0 && 
            rect.top <= (window.innerHeight || document.documentElement.clientHeight) && 
            rect.left <= (window.innerWidth || document.documentElement.clientWidth)
         );
    }

    async componentDidMount() {
        if(this.view && this.view.base) {
            this.view.base.addEventListener('scroll', this.onScroll, false);
        }
        window.addEventListener('resize', this.onScroll, false);
        setTimeout(() => {
            this.onScroll();
        }, 1);

        this.refreshCommunityList();
    }

    async refreshCommunityList() {
        try {
            const comms = await Communities.all();

            this.communitySelection = {};

            comms.items.sort((a,b) => {
                if(a.name < b.name) {
                    return -1;
                }
                else if(a.name > b.name) {
                    return 1;
                }

                return 0;
            });

            comms.items.forEach(i => {
                const isAdded = Communities.isAppBundleAssigned(i.name, this.item.bundleId);
                this.communitySelection[i.name] = isAdded;
            });
        }
        catch (e) {
            console.log(e);
        }
    }

    async componentWillUnmount() {
        if(this.view && this.view.base) {
            this.view.base.removeEventListener('scroll', this.onScroll, false);
        }
        window.removeEventListener('resize', this.onScroll, false);
    }

    async render() {
        const content = [
            <h1 class='mb-2 ml-2'>{this.item.name}</h1>,
            <div class='abs-tr d-flex flex-row w-100 flex-wrap justify-content-end'>
                <MyndButton class='mr-2' onclick={this.onCommunityToggle}>ASSIGNED COMMUNITIES</MyndButton>
                <MyndButton type='light' class='round-button mr-2 text-dark material-shadow' onclick={this.onSelect}>
                    <i class='fas fa-pencil-alt' style='font-size: 10pt;'></i>
                </MyndButton>
            </div>
        ];

        if(this.showCommunitySelector) {
            content.push(<MultiCheckboxSelector saving={this.showSaving} oncancel={this.onCommunityToggle} onsubmit={this.onCommunitiesSelected}
                submitTitle='APPLY' cancelTitle='CANCEL' items={this.communitySelection} />)
        }

        return (
            <div class='app-item relative ml-2 mr-2' ref={f => this.div = f} 
                style={`background-image: url("${this.isVisible ? this.item.iconUrl : this.image}");`}>
                <div class='d-flex flex-column justify-content-end app-info text-white' style='height: 100%;'>
                    {content}
                </div>
            </div>
        )
    }
}

export default class AppsList extends Component {
    constructor(o, parent) {
        super(o, parent);
        this.observe({apps: [], showAdd: false});
        this.onToggleAddClick = this.onToggleAddClick.bind(this);
        this.onSubmission = this.onSubmission.bind(this);
        this.errorMessage = 'Loading';
        this.items = null;
    }

    onToggleAddClick(e) {
        if(e) {
            e.stopPropagation();
            e.preventDefault();
        }

        if(!this.showAdd) {
            this.showAdd = true;
        }
        else if(this.showAdd) {
            this.showAdd = false;
        }
    }

    async onSubmission() {
        this.onToggleAddClick();
        this.refresh();
    }

    async componentDidMount() {
        this.refresh();
    }

    async refresh() {
        if(Login.hasGroup('Admin')) {
            try {
                console.log('refreshing apps list');
                const list = await Apps.all;
                const items = [];

                this.items = list;

                list.sort((a,b) => {
                    if(a.name < b.name) {
                        return -1;
                    }
                    else if(a.name > b.name) {
                        return 1;
                    }

                    return 0;
                });

                for(let i = 0; i < list.length; i++) {
                    const item = list[i];
                    items.push(<AppListItem key={`app-${i}`} view={this} item={item} index={i} />);
                }

                this.errorMessage = 'No apps found';
                this.apps = items;
            }
            catch (e) {
                console.log(e);
            }
        }
    }

    async render() {
        if(this.edit !== null) {
            try {
                return (<AppEditView view={this} item={this.items[this.edit]} key='view' />);
            }
            catch(e) {
                return (<div class='flex-fill d-flex flex-column allow-scroll-y relative' style='margin-top: 64px;' key='view' />);
            }
        }
        else {
            let content = [
                <ListViewGrid noItemsMessage={this.errorMessage} items={this.apps} />,
                <MyndFloatingButton icon='fas fa-plus' onclick={this.onToggleAddClick} />
            ];

            if(this.showAdd) {
                content.push(<AppAddView onSubmission={this.onSubmission} />);
            }

            return (
                <div class='flex-fill d-flex flex-column allow-scroll-y relative' style='margin-top: 76px;' key='view'>
                    {content}
                </div>
            );
        }
    }
}